import * as React from "react"

function SvgCalendar(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 479.93"
      width="1em"
      height="1em"
      {...props}
    >
      <g id="calendar_svg__Livello_2" data-name="Livello 2">
        <g id="calendar_svg__Livello_1-2" data-name="Livello 1">
          <path
            fill={props.accent}
            d="M472.44 39.57H39.57A32.06 32.06 0 007.5 71.63v32.06h497V71.63a32.06 32.06 0 00-32.06-32.06z"
          />
          <path
            fill={props.panel}
            d="M472.44 472.44H39.57A32.07 32.07 0 017.5 440.37V103.69h497v336.68a32.06 32.06 0 01-32.06 32.07zM71.63 71.63a16 16 0 01-16-16v-32.1a16 16 0 1132.06 0V55.6a16 16 0 01-16.06 16.03zM440.37 71.63a16 16 0 01-16-16v-32.1a16 16 0 0132.06 0V55.6a16 16 0 01-16.06 16.03z"
          />
          <path fill={props.border} d="M472.44 32.07h-8.54v-8.54a23.53 23.53 0 00-47.06 0v8.53H119.73a7.5 7.5 0 000 15h297.11v8.54a23.53 23.53 0 0047.06 0v-8.53h8.53A24.59 24.59 0 01497 71.63v24.56h-40.6a7.5 7.5 0 100 15H497v257.55h-56.63a39.61 39.61 0 00-39.56 39.56v56.63H39.56A24.59 24.59 0 0115 440.37V111.19h409.34a7.5 7.5 0 100-15H15V71.63a24.59 24.59 0 0124.56-24.57h8.54v8.54a23.53 23.53 0 1047.06 0V23.53a23.53 23.53 0 10-47.06 0v8.53h-8.54A39.61 39.61 0 000 71.63v368.74a39.61 39.61 0 0039.56 39.56h432.87A39.53 39.53 0 00512 440.37v-16a7.5 7.5 0 00-15 0v16a24.53 24.53 0 01-24.56 24.56h-46l83.39-83.39a7.48 7.48 0 002.18-5.3V71.63a39.61 39.61 0 00-39.56-39.56zm-32.07 32.06a8.54 8.54 0 01-8.53-8.53V23.53a8.53 8.53 0 0117.06 0V55.6a8.54 8.54 0 01-8.53 8.53zM71.63 15a8.54 8.54 0 018.53 8.53V55.6a8.53 8.53 0 01-17.06 0V23.53A8.54 8.54 0 0171.63 15zm344.18 439.33v-46a24.59 24.59 0 0124.56-24.56h46z" />
          <rect
            fill={props.accent}
            x={79.52}
            y={157.54}
            width={66.43}
            height={64.52}
            rx={5}
            ry={5}
          />
          <rect
            fill={props.accent}
            x={178.07}
            y={157.54}
            width={66.43}
            height={64.52}
            rx={5}
            ry={5}
          />
          <rect
            fill={props.accent}
            x={272.93}
            y={157.54}
            width={66.43}
            height={64.52}
            rx={5}
            ry={5}
          />
          <rect
            fill={props.accent}
            x={367.79}
            y={157.54}
            width={66.43}
            height={64.52}
            rx={5}
            ry={5}
          />
          <rect
            fill={props.accent}
            x={77.78}
            y={258.18}
            width={66.43}
            height={64.52}
            rx={5}
            ry={5}
          />
          <rect
            fill={props.accent}
            x={176.33}
            y={258.18}
            width={66.43}
            height={64.52}
            rx={5}
            ry={5}
          />
          <rect
            fill={props.accent}
            x={271.19}
            y={258.18}
            width={66.43}
            height={64.52}
            rx={5}
            ry={5}
          />
          <rect
            fill={props.accent}
            x={366.04}
            y={258.18}
            width={66.43}
            height={64.52}
            rx={5}
            ry={5}
          />
          <rect
            fill={props.accent}
            x={79.63}
            y={358.81}
            width={66.43}
            height={64.52}
            rx={5}
            ry={5}
          />
          <rect
            fill={props.accent}
            x={178.17}
            y={358.81}
            width={66.43}
            height={64.52}
            rx={5}
            ry={5}
          />
          <rect
            fill={props.accent}
            x={272.93}
            y={358.81}
            width={64.69}
            height={64.52}
            rx={5}
            ry={5}
          />
        </g>
      </g>
    </svg>
  )
}

export default SvgCalendar

