import React from "react"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import DialogTitle from "@material-ui/core/DialogTitle"
import Dialog from "@material-ui/core/Dialog"

import {
  Button,
  DialogActions,
  DialogContent,
  Divider,
  Grid,
} from "@material-ui/core"
import {
  useTheme,
  makeStyles,
  createStyles,
  Theme,
} from "@material-ui/core/styles"
import useMediaQuery from "@material-ui/core/useMediaQuery"

import { GuideCard } from "../guide-card/guide-card"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    avatar: {
      backgroundColor: "inherit",
      color: "inherit",
    },
    dialogtitle: {
      margin: "2rem",
      marginBottom: 0,
      "& h2": {
        textAlign: "center",
        textDecoration: "underline",
      },
    },
    title: {
      textAlign: "center",
      marginTop: "2rem",
      marginBottom: "0.5rem",
    },
    large: {
      width: theme.spacing(20),
      height: theme.spacing(20),
    },
    guidename: {
      margin: 0,
      marginTop: "0.4rem",
      marginBottom: "0.2rem",
      paddingLeft: "0.5rem",
      paddingRight: "0.5rem",
    },
    cardcontainer: {
      marginTop: "1rem",
      marginBottom: "1rem",
    },
  })
)

export interface GuidesDialogProps {
  open: boolean
  selectedTour: string
  selectedDate: string
  onClose: () => void
  children: Array<{
    name: string
    profilepic: any
    nickname: string
    surname: string
    bio: string
  }>
}

export function GuidesDialog(props: GuidesDialogProps) {
  const classes = useStyles()
  const theme = useTheme()

  const { onClose, selectedTour, selectedDate, open, children } = props

  const handleClose = () => {
    onClose()
  }

  const supportguides = children.filter(item => item !== children[0])

  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"))

  return (
    <Dialog
      fullScreen={fullScreen}
      aria-labelledby="guides-info-dialog"
      open={open}
      onClose={handleClose}
      // scroll="body"
    >
      <div className={classes.dialogtitle} id="simple-dialog-title">
        {supportguides.length === 0 ? (
          <h2>Guide Info</h2>
        ) : (
          <h2>Guides Info</h2>
        )}
      </div>
      <DialogContent>
        <h3 className={classes.title}>Main guide</h3>
        <Divider style={{ marginBottom: "30px" }} variant="middle" />
        <div className={classes.cardcontainer}>
          <GuideCard
            name={children[0].name}
            surname={children[0].surname}
            nickname={children[0].nickname}
            profilepic={children[0].profilepic.childImageSharp.fluid}
            bio={children[0].bio}
          />
        </div>

        {supportguides.length === 0
          ? ""
          : [
              supportguides.length === 1 ? (
                <h3 key="onesupport" className={classes.title}>Support guide</h3>
              ) : (
                <h3 key="moresupport" className={classes.title}>Support guides</h3>
              ),
            ]}
        {supportguides.length === 0 ? (
          ""
        ) : (
          <Divider style={{ marginBottom: "30px" }} variant="middle" />
        )}
        {supportguides.map(
          ({ name, surname, nickname, profilepic, bio }, index) => (
            <div key={index} className={classes.cardcontainer}>
              <GuideCard
                name={name}
                surname={surname}
                nickname={nickname}
                profilepic={profilepic.childImageSharp.fluid}
                bio={bio}
              />
            </div>
          )
        )}
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleClose} color="primary">
          Back
        </Button>
      </DialogActions>
    </Dialog>
  )
}
