import React from "react"
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles"
import Accordion from "@material-ui/core/Accordion"
import AccordionSummary from "@material-ui/core/AccordionSummary"
import AccordionDetails from "@material-ui/core/AccordionDetails"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import ReactMarkdown from "react-markdown"

export interface QaProps {
  children: Array<{ question: string; answer: string }>
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      marginTop: "1.5rem",
      marginBottom: "1.5rem",
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
    question: {
      fontWeight: "bold",
    },
    answer: {
      wordBreak: "break-all",
      overflow: "hidden",
    },
  })
)

export const Qa: React.FC<QaProps> = ({ children }) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      {/* <Accordion disabled> */}
      {children.map(({ question, answer }, index) => (
        <Accordion key={index}>
          <AccordionSummary
            className={classes.question}
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <p>{question}</p>
          </AccordionSummary>
          <AccordionDetails className={classes.answer}>
            <ReactMarkdown source={answer} />
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  )
}
