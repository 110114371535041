import React from "react"
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import CardMedia from "@material-ui/core/CardMedia"

import Img, { FluidObject } from "gatsby-image"

export interface DetailsCardProps {
  thumbnail: FluidObject
  description: string
  imgoffset: number
  alt: string
}

const useStyles = makeStyles<Theme, { imgoffset }>(theme =>
  createStyles({
    cardwrapper: {
      maxWidth: 280,
      marginLeft: "auto",
      marginRight: "auto",
      height: "100%",
    },
    card: {
      height: "inherit",
      position: "relative",
      margin: "0.5rem",
      backgroundColor: theme.palette.secondary.main,
      "& a:hover": {
        textDecoration: "none",
      },
    },
    media: {
      height: 280,
      overflow: "hidden",
      position: "relative",
    },
    image: {
      top: ({ imgoffset }) => `calc(-${imgoffset}%)`,
      // top: "-20%",
    },
    description: {
      fontSize: "0.9em",
    },
  })
)

export const DetailsCard: React.FC<DetailsCardProps> = ({
  thumbnail,
  description,
  imgoffset,
  alt,
}) => {
  const classes = useStyles({ imgoffset })

  return (
    <div className={classes.cardwrapper}>
      <Card className={classes.card}>
        <CardMedia className={classes.media}>
          <Img fluid={thumbnail} alt={alt} className={classes.image} />
        </CardMedia>
        <CardContent className={classes.description}><p>{description}</p></CardContent>
      </Card>
    </div>
  )
}
