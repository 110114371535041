import React from "react"
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles"
import Paper from "@material-ui/core/Paper"
import Grid from "@material-ui/core/Grid"
import CalendarIcon from "../svg-comps/calendar"
import { Avatar, Button, IconButton } from "@material-ui/core"
import MessageOutlinedIcon from "@material-ui/icons/MessageOutlined"
import ShoppingCartOutlinedIcon from "@material-ui/icons/ShoppingCartOutlined"
import StarBorderOutlinedIcon from "@material-ui/icons/StarBorderOutlined"
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined"

import { ContactDialog } from "../contact-dialog/contact-dialog"
import { GuidesDialog } from "../guides-dialog/guides-dialog"
import SvgComingSoon from "../svg-comps/coming-soon"
// import { AvatarGroup } from "@material-ui/lab"

export interface DatesProps {
  currentTour: string
  defprice: number
  defoffer: boolean
  defofferprice: number
  children: Array<{
    id: number
    start: string
    duration: number
    usedefprice: boolean
    price: number
    offer: boolean
    offerprice: number
    guides: Array<{
      name: string
      profilepic: any
      nickname: string
      surname: string
      email: string
      bio: string
    }>
  }>
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    box: {
      minWidth: "230px",
      // marginTop: "0.5rem",
    },
    eventline: {
      alignContent: "center",
      justifyContent: "center",
      padding: "1rem",
    },
    info: {
      padding: "1rem",
      borderStyle: "solid",
      borderColor: theme.palette.primary.main,
      borderWidth: "3px",
      borderRadius: "10px",
    },
    comingsoon: {
      // textAlign: "center",
      // textDecoration: "underline",
      display: "block",
      margin: "0.5rem",
      marginRight: "auto",
      marginLeft: "auto",
      height: "180px",
      width: "200px",
    },
    sidebutton: {
      fontFamily: "inherit",
      textTransform: "none",
      fontWeight: "bold",
    },
    large: {
      width: theme.spacing(11),
      height: theme.spacing(11),
    },
    guidename: {
      margin: 0,
      marginTop: "0.4rem",
      marginBottom: "0.2rem",
      paddingLeft: "0.5rem",
      paddingRight: "0.5rem",
    },
    price: {
      // color: theme.palette.primary.main,
      marginBottom: "0.1rem",
      marginTop: "0.5rem",
    },
    oldprice: {
      marginBottom: "0.1rem",
      marginTop: "0.5rem",
      margin: "0.5rem",
      textDecoration: "line-through",
    },
    highlights: {
      margin: 0,
      marginTop: "0.2rem",
      marginBottom: "0.2rem",
      paddingLeft: "0.5rem",
      paddingRight: "0.5rem",
    },
    startday: {
      margin: 0,
      marginTop: "0.5rem",
      marginBottom: "0.2rem",
      paddingLeft: "0.5rem",
      paddingRight: "0.5rem",
      color: theme.palette.primary.main,
    },
    button: {
      margin: theme.spacing(1),
    },
  })
)

export const Dates: React.FC<DatesProps> = ({
  children,
  defprice,
  defoffer,
  defofferprice,
  currentTour,
}) => {
  const classes = useStyles()
  const [openContact, setOpenContact] = React.useState(false)
  const [openGuides, setOpenGuides] = React.useState(false)

  var offer_dict = {}
  var offerprice_dict = {}
  var price_dict = {}

  children.forEach(date => {
    // console.log(date.id, date.start, date.usedefprice)
    offer_dict[date.id] = date.usedefprice ? defoffer : date.offer
    offerprice_dict[date.id] = date.usedefprice
      ? defofferprice
      : date.offerprice
    price_dict[date.id] = date.usedefprice ? defprice : date.price
    // console.log(date.id, offer_dict[date.id], offerprice_dict[date.id], price_dict[date.id])
  })

  const handleClickOpenContact = () => {
    setOpenContact(true)
  }

  const handleCloseContact = () => {
    setOpenContact(false)
  }

  const handleClickOpenGuides = () => {
    setOpenGuides(true)
  }

  const handleCloseGuides = () => {
    setOpenGuides(false)
  }

  if (children.length === 0) {
    return (
      <div className={classes.eventline}>
        <Paper elevation={0} className={classes.info}>
          {/* <h3 className={classes.comingsoon}>Coming Soon</h3> */}
          <SvgComingSoon className={classes.comingsoon} />
        </Paper>
      </div>
    )
  } else {
    return (
      <div>
        {children.map(({ id, start, duration, guides }, index) => (
          <div key={index} className={classes.eventline}>
            <Paper elevation={0} className={classes.info}>
              <Grid
                container
                direction="row"
                alignItems="center"
                justify="center"
                spacing={2}
              >
                <Grid
                  id="dates"
                  xs={12}
                  sm={4}
                  container
                  item
                  direction="column"
                  alignItems="center"
                  justify="center"
                  className={classes.box}
                >
                  <Grid
                    container
                    direction="row"
                    alignItems="center"
                    justify="center"
                  >
                    <Grid item>
                      <CalendarIcon
                        border="black"
                        accent="teal"
                        panel="#fefefe"
                        width="2em"
                        height="2em"
                      />
                    </Grid>
                    <Grid item>
                      <h2 className={classes.startday}>{start}</h2>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    direction="row"
                    alignItems="center"
                    justify="center"
                  >
                    <Grid item>
                      <h2 className={classes.highlights}>{duration}</h2>
                    </Grid>
                    <Grid item>
                      <h2 className={classes.highlights}>
                        {duration == 1 ? "день" : ""}
                      </h2>
                      <h2 className={classes.highlights}>
                        {duration > 1 && duration < 5 ? "дні" : ""}
                      </h2>
                      <h2 className={classes.highlights}>
                        {duration >= 5 ? "днів" : ""}
                      </h2>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  id="guides"
                  xs={12}
                  sm={4}
                  container
                  item
                  direction="row"
                  alignItems="center"
                  justify="center"
                  className={classes.box}
                >
                  <Grid>
                    <Avatar
                      key={index}
                      alt={guides[0].nickname}
                      src={guides[0].profilepic.childImageSharp.fluid.srcWebp}
                      className={classes.large}
                      imgProps={{ style: { marginBottom: 0 } }}
                    />
                  </Grid>
                  <Grid item>
                    <h5 className={classes.highlights}>Головний гід</h5>
                    <h4 className={classes.guidename}>{guides[0].nickname}</h4>
                    <Button
                      // variant="outlined"
                      color="inherit"
                      size="small"
                      className={classes.button}
                      startIcon={<InfoOutlinedIcon />}
                      style={{ marginTop: "0.1rem", marginBottom: "0.1rem" }}
                      onClick={handleClickOpenGuides}
                    >
                      ІНФО
                    </Button>
                  </Grid>
                </Grid>
                <Grid
                  id="price"
                  xs={12}
                  sm={4}
                  container
                  item
                  justify="center"
                  alignItems="center"
                  className={classes.box}
                >
                  <Grid container alignItems="center" justify="center">
                    <h4 className={classes.oldprice}>
                      {offer_dict[id] ? price_dict[id] : ""}
                    </h4>
                    <h1 className={classes.price}>
                      {offer_dict[id] ? offerprice_dict[id] : price_dict[id]} €
                    </h1>
                  </Grid>
                  <Grid container alignItems="center" justify="center">
                    {/* <IconButton
                      size="medium"
                      aria-label="save"
                      component="span"
                      color="inherit"
                      onClick={handleClickOpenContact}
                    >
                      <MessageOutlinedIcon />
                    </IconButton> */}
                    {/* <IconButton
                      size="medium"
                      aria-label="save"
                      component="span"
                      color="inherit"
                    >
                      <StarBorderOutlinedIcon />
                    </IconButton> */}
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.button}
                      startIcon={<ShoppingCartOutlinedIcon />}
                      onClick={handleClickOpenContact}
                    >
                      Купити - ІНФО
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
            <ContactDialog
              selectedTour={currentTour}
              selectedDate={start}
              open={openContact}
              onClose={handleCloseContact}
            />
            <GuidesDialog
              selectedTour={currentTour}
              selectedDate={start}
              open={openGuides}
              onClose={handleCloseGuides}
            >
              {guides}
            </GuidesDialog>
          </div>
        ))}
      </div>
    )
  }
}
