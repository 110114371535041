import React from "react"
import { Link } from "gatsby"
import { makeStyles } from "@material-ui/core/styles"
import Avatar from "@material-ui/core/Avatar"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemAvatar from "@material-ui/core/ListItemAvatar"
import ListItemText from "@material-ui/core/ListItemText"
import DialogTitle from "@material-ui/core/DialogTitle"
import Dialog from "@material-ui/core/Dialog"

import { EmailIcon, TelegramIcon, WhatsappIcon } from "react-share"
import InstagramIcon from "@material-ui/icons/Instagram"

const useStyles = makeStyles({
  avatar: {
    backgroundColor: "inherit",
    color: "inherit",
  },
  sharebutton: {
    width: "100%",
    color: "inherit",
  },
  instagram: {
    background:
      "radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%)",
  },
})

export interface ContactDialogProps {
  open: boolean
  selectedTour: string
  selectedDate: string
  onClose: () => void
}

export function ContactDialog(props: ContactDialogProps) {
  const classes = useStyles()
  const { onClose, selectedTour, selectedDate, open } = props
  const emaildata =
    "mailto:olesya.amstel@gmail.com?subject=Info about tour to " +
    selectedTour +
    " " +
    selectedDate
  const waURL =
    "https://wa.me/380639891253/" +
    "?text=*Info about tour to " +
    selectedTour +
    " " +
    selectedDate +
    "*%20"
  const tgURL = "https://t.me/shooowon/"

  const handleClose = () => {
    onClose()
  }

  const handleListItemClick = (value: string) => {
    onClose()
  }

  return (
    <Dialog
      aria-labelledby="simple-dialog-title"
      open={open}
      onClose={handleClose}
    >
      <DialogTitle id="simple-dialog-title">Написати нам</DialogTitle>
      <List>
        <a
          className={classes.sharebutton}
          href={emaildata}
          target="_blank"
          rel="noreferrer"
        >
          <ListItem button>
            <ListItemAvatar>
              <Avatar>
                <EmailIcon size={48} round={true} />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="E-Mail" />
          </ListItem>
        </a>

        <Link
          className={classes.sharebutton}
          to={waURL}
          target="_blank"
          rel="noreferrer"
        >
          <ListItem button>
            <ListItemAvatar>
              <Avatar>
                <WhatsappIcon size={48} round={true} />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="WhatsApp" />
          </ListItem>
        </Link>

        <Link
          className={classes.sharebutton}
          to={tgURL}
          target="_blank"
          rel="noreferrer"
        >
          <ListItem button>
            <ListItemAvatar>
              <Avatar>
                <TelegramIcon size={48} round={true} />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="Telegram" />
          </ListItem>
        </Link>

        <Link
          className={classes.sharebutton}
          to="https://www.instagram.com/where.again/"
          target="_blank"
          rel="noreferrer"
        >
          <ListItem button>
            <ListItemAvatar>
              <Avatar className={classes.instagram}>
                <InstagramIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="Instagram" />
          </ListItem>
        </Link>
      </List>
    </Dialog>
  )
}
