import React from "react"
import {
  Theme,
  createStyles,
  makeStyles,
  useTheme,
} from "@material-ui/core/styles"
import { Avatar, Grid, Paper } from "@material-ui/core"
import { FluidObject } from "gatsby-image"

export interface GuideCardProps {
  profilepic: FluidObject
  name: string
  surname: string
  nickname: string
  bio: string
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: "1rem",
      maxWidth: 500,
      marginLeft: "auto",
      marginRight: "auto",
    },
    details: {
      display: "flex",
      flexDirection: "column",
    },
    content: {
      flex: "1 0 auto",
    },
    avatar: {
      width: theme.spacing(15),
      height: theme.spacing(15),
      margin: "1rem",
    },
  })
)

export const GuideCard: React.FC<GuideCardProps> = ({
  profilepic,
  name,
  surname,
  nickname,
  bio,
}) => {
  const classes = useStyles()

  return (
    <Paper elevation={2} className={classes.root}>
      <Grid container spacing={2} direction="column" alignItems="center" justify="center">
        <Grid
          container
          spacing={2}
          direction="row"
          alignItems="center"
          justify="center"
        >
          <Grid container item xs={12} sm={6} justify="center">
            <Avatar
              src={profilepic.srcWebp}
              className={classes.avatar}
              imgProps={{ style: { margin: 0 } }}
            />
          </Grid>
          <Grid container item xs={12} sm={4} direction="column" justify="center">
            <h2 style={{ margin: "0.2rem", textAlign: "center" }}>{nickname}</h2>
            <h6 style={{ margin: "0.2rem", textAlign: "center" }}>
              {name} {surname}
            </h6>
          </Grid>
        </Grid>
        <Grid container item xs direction='column'>
          <h4 style={{ margin: "0.2rem", marginBottom: "0.5rem", marginTop: "1rem" }}>Bio:</h4>
          <p style={{ margin: "0.2rem" }}>{bio}</p>
        </Grid>
      </Grid>
    </Paper>
  )
}
