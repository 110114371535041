import React from "react"
import Layout from "../../components/layout/layout"
import { graphql } from "gatsby"
import { Hero } from "../../components/hero/hero"
import { TourGallery } from "../../components/tourgallery/tourgallery"
import { Qa } from "../../components/qa/qa"
import { Dates } from "../../components/dates/dates"
import { Divider, Grid } from "@material-ui/core"
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles"
import ReactMarkdown from "react-markdown"
import Paper from "@material-ui/core/Paper"

import { Feature } from "../../components/feature/feature"
import Carousel from "react-multi-carousel" //https://www.npmjs.com/package/react-multi-carousel
import "react-multi-carousel/lib/styles.css"
import { DetailsCard } from "../../components/details-card/details-card"

import SEO from "../../components/seo"

export const data = graphql`
  query($slug: String!) {
    strapiTours(slug: { eq: $slug }) {
      title
      summary
      hero {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      datelist {
        start(formatString: "DD/MM/yyyy")
        duration
        selling
        price
        offer
        offerprice
        usedefprice
        id
        guides {
          name
          surname
          nickname
          bio
          profilepic {
            childImageSharp {
              fluid(quality: 90, maxWidth: 180) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
      id
      price
      offer
      offerprice
      included
      notincluded
      qalist {
        question
        answer
      }
      continents {
        name
      }
      features {
        description
        title
        icon {
          childImageSharp {
            fluid(quality: 90, maxWidth: 250) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      details {
        description
        alt
        imgoffset
        thumbnail {
          childImageSharp {
            fluid(quality: 90, maxWidth: 600) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
    allStrapiTours {
      edges {
        node {
          summary
          slug
          title
          featured
          thumbnailoffset
          datelist {
            selling
          }
          thumbnail {
            childImageSharp {
              fluid(maxWidth: 960) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`

const useStyles = makeStyles<Theme>(theme =>
  createStyles({
    title: {
      textAlign: "center",
      marginTop: "4rem",
    },
    include: {
      padding: "1.5rem",
      paddingTop: "2.5rem",
    },
    details: {
      maxWidth: "960px",
      marginLeft: "auto",
      marginRight: "auto",
      paddingBottom: "3rem",
      "& button": {
        backgroundColor: theme.palette.primary.main,
        bottom: 0,
      },
      "& button:before": {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.secondary.main,
      },
      "& button:hover": {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.secondary.main,
      },
    },
    detailsdots: {
      "& li > button": {
        marginRight: "0.6vw",
        width: "3vw",
        height: "3vw",
        maxWidth: "14px",
        maxHeight: "14px",
      },
    },
    features: {
      maxWidth: "960px",
      margin: "1rem",
      marginLeft: "auto",
      marginRight: "auto",
      padding: "1rem",
    },
    feature: {
      minWidth: "250px",
    },
  })
)

export default function Tour({ data }) {
  const classes = useStyles()
  const tour = data.strapiTours

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 900 },
      items: 3,
      slidesToSlide: 3, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 900, min: 580 },
      items: 2,
      slidesToSlide: 2, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 580, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  }

  return (
    <div>
      <SEO title={tour.title} image={tour.hero.childImageSharp.fluid.src} description={tour.summary}/>
      <Hero backgroundimage={tour.hero.childImageSharp.fluid}>
        <React.Fragment>{tour.summary}</React.Fragment>
      </Hero>
      <Layout>
        {/* Details gallery */}
        <h1 className={classes.title} style={{ fontSize: "2em" }}>
          Головні враження подорожі
        </h1>
        <Divider style={{ marginBottom: "30px" }} variant="middle" />
        <Carousel
          responsive={responsive}
          infinite={true}
          autoPlay={true}
          autoPlaySpeed={4000}
          ssr={true}
          keyBoardControl={true}
          renderButtonGroupOutside={true}
          showDots={true}
          className={classes.details}
          dotListClass={classes.detailsdots}
        >
          {tour.details.map(
            ({ thumbnail, alt, description, imgoffset }, index) => (
              <DetailsCard
                key={index}
                thumbnail={thumbnail.childImageSharp.fluid}
                alt={alt}
                description={description}
                imgoffset={imgoffset}
              />
            )
          )}
        </Carousel>

        {/* Dates section */}
        <h1 className={classes.title}>Дати турів</h1>
        <Divider style={{ marginBottom: "30px" }} variant="middle" />
        <Dates
          defprice={tour.price}
          defoffer={tour.offer}
          defofferprice={tour.offerprice}
          currentTour={tour.title}
        >
          {tour.datelist.filter(event => event.selling == true)}
        </Dates>

        {/* Features section */}
        <h1 className={classes.title}>Деталі подорожі</h1>
        <Divider style={{ marginBottom: "30px" }} variant="middle" />
        <div className={classes.features}>
          <Grid container spacing={2} alignContent="center" justify="center">
            {tour.features.map(({ icon, title, description }, index) => (
              <Grid key={index} item xs className={classes.feature}>
                <Feature
                  thumbnail={icon.childImageSharp.fluid}
                  title={title}
                  description={description}
                />
              </Grid>
            ))}
          </Grid>
        </div>

        <h1 className={classes.title}>У вартість туру включено</h1>
        <Divider style={{ marginBottom: "30px" }} variant="middle" />
        <Paper elevation={1} className={classes.include}>
          <ReactMarkdown source={tour.included} />
        </Paper>

        <h1 className={classes.title}>У вартість не включено</h1>
        <Divider style={{ marginBottom: "30px" }} variant="middle" />
        <Paper elevation={1} className={classes.include}>
          <ReactMarkdown source={tour.notincluded} />
        </Paper>

        <h1 className={classes.title}>Поширені запитання</h1>
        <Divider style={{ marginBottom: "30px" }} variant="middle" />
        <Qa>{tour.qalist}</Qa>

        <h1 className={classes.title}>Вибрати інші</h1>
        <Divider style={{ marginBottom: "30px" }} variant="middle" />
        <TourGallery>{data.allStrapiTours.edges}</TourGallery>
      </Layout>
    </div>
  )
}
